import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import { api } from "../../../utils/api";
import paths from "../../../routes/paths";
import { toast } from "react-toastify";
import Flex from "../../../components/common/Flex";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Field, Formik } from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import Select from "react-select";
import { getUsers } from "../../account/actions/Users";
import FormError from "../../errors/FormError";
import IconButton from "../../../components/common/IconButton";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { withPermission } from "../../../helpers/utils";
import { withTranslation } from "react-i18next";

const StudentAddEdit = ({ t, i18n }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    user: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const query = useQuery();

  const fetchUsers = async () => {
    setLoading(true);
    query.set("page_size", "50");
    query.set("page", page.toString());
    await api
      .get(`/account/user/?${query.toString()}`)
      .then(({ data }) => setUsers([...users, ...data?.results]));
    setLoading(false);
  };

  const fetchStudent = async () => {
    setLoading(true);
    await api
      .get(`/student/${id}/`)
      .then(({ data }) => {
        setFormData(data);
        setSelectedUser({
          label: data?.user_full_name,
          value: data?.user,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 404) navigate(paths.error404);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (id) fetchStudent();
    // eslint-disable-next-line
  }, []);

  let userOptions = users?.map((user) => ({
    label: user.full_name,
    value: user.id,
  }));

  userOptions = [
    { label: "---------------------------", value: "" },
    ...userOptions,
  ];

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    setLoading(true);
    if (id) {
      await api
        .patch(`/student/${id}/`, values)
        .then((res) => {
          toast.success("Student successfully updated.", { theme: "colored" });
          navigate(paths.studentList);
        })
        .catch((error) => {
          setFormData(values);
          toast.error("An error has occurred.", { theme: "colored" });
          setErrors(error?.response?.data);
        });
    } else {
      await api
        .post(`/student/`, values)
        .then((res) => {
          toast.success("Student successfully added.", { theme: "colored" });
          navigate(paths.studentList);
        })
        .catch((error) => {
          setFormData(values);
          toast.error("An error has occurred.", { theme: "colored" });
          setErrors(error?.response?.data);
        });
    }
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Formik initialValues={formData} onSubmit={(values) => console.log(values)}>
      {({ values, setFieldValue }) => (
        <Form>
          <CSRFToken />
          <Card>
            <Card.Header>
              <h5 className="mb-0 text-muted">{t("basic")}</h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      {t("fields.user")}{" "}
                      <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"user"}>
                      {({ field }) => (
                        <Select
                          options={userOptions}
                          value={selectedUser}
                          onChange={(newValue) => {
                            setSelectedUser(newValue);
                            setFieldValue(field.name, newValue.value);
                          }}
                          placeholder={`${t("select", { ns: "common" })} ${t(
                            "fields.user"
                          )}`}
                          classNamePrefix={"react-select"}
                          onInputChange={(newValue) => {
                            query.set("search", newValue);
                            getUsers(query).then((data) =>
                              setUsers(data?.results)
                            );
                          }}
                          onMenuScrollToBottom={() => {
                            setPage((prevState) => prevState + 1);
                          }}
                        />
                      )}
                    </Field>
                    <FormError error={errors?.user} />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Flex
                justifyContent={"between"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <span>
                  <ul>
                    <li className={"text-decoration-none text-danger"}>
                      * {t("mandatory", { ns: "common" })}
                    </li>
                    <li className={"text-decoration-none text-warning"}>
                      * {t("recommended", { ns: "common" })}
                    </li>
                  </ul>
                </span>
                <IconButton
                  icon={faSave}
                  onClick={(e) => handleSubmit(e, values)}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    {t("save", { ns: "common" })}
                  </span>
                </IconButton>
              </Flex>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default withPermission(
  withTranslation(["student", "common"])(StudentAddEdit),
  "trainer.add_trainer"
);
