import AuthSplitLayout from "../../../layouts/AuthSplitLayout";
import bgImg from "../../../assets/img/generic/17.jpg";
import Flex from "../../../components/common/Flex";
import React from "react";
import { withTranslation } from "react-i18next";
import StudyFieldForm from "../StudyFieldForm";

const StudentStudyField = ({ t, i18n }) => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Field of study</h3>
      </Flex>
      <StudyFieldForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(StudentStudyField);
