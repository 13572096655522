import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../providers/AppProvider";
import { usePermissions } from "../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import { api } from "../../utils/api";
import paths from "../../routes/paths";
import { debounce } from "lodash";
import Flex from "../../components/common/Flex";
import { formatDateTime, withPermission } from "../../helpers/utils";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import { Card, Dropdown, Spinner } from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../BaseTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";

const Teachers = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortingField, setSortingField] = useState("created_at"); // Set the initial sorting field
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const query = useQuery();

  const { t } = useTranslation(["trainer", "common"]);

  const { setConfig } = useAppContext();
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();

  const fetchTeachers = async () => {
    setLoading(true);
    const sortingParam = `${
      sortingDirection === "desc" ? "-" : ""
    }${sortingField}`;

    query.set("ordering", sortingParam);
    await api
      .get(`/trainer/trainer/?${query.toString()}`)
      .then((res) => {
        setTeachers(res?.data?.results);
        setCount(res?.data?.count);
      })
      .catch((error) => {
        if (error?.response?.status === 404) navigate(paths.error404);
        if (error?.response?.status === 500) navigate(paths.error500);
        if (error?.response?.status === 403)
          setConfig("isAuthenticated", false);
      });
    setLength(teachers.length);
    setLoading(false);
  };

  // eslint-disable-next-line
  const delayedLoadItems = useCallback(debounce(fetchTeachers, 600), []);

  useEffect(() => {
    fetchTeachers(query);
    setLength(teachers.length);
    navigate(`?${query.toString()}`);
    // eslint-disable-next-line
  }, [sortingField, sortingDirection]);

  const columns = [
    {
      accessor: "full_name",
      Header: t("fields.full_name"),
      cellProps: {
        className: "py-2",
      },
      headerProps: { className: "pe-3" },
      Cell: rowData => {
        const { full_name, id } = rowData.row.original;
        return <Link to={paths.teacherProfile(id)}>{full_name}</Link>
      }
    },
    {
      accessor: "organization",
      Header: t("fields.Organisation"),
      canSort: true,
      sortingDirection: sortingField === "organization" && sortingDirection,
      isSorted: false,
      cellProps: {
        className: "py-2",
      },
      headerProps: { className: "pe-3" },
    },
    {
      accessor: "created_at",
      Header: t("fields.createdAt"),
      canSort: true,
      sortingDirection: sortingField === "created_at" && sortingDirection,
      isSorted: false,
      headerProps: {
        className: "text-center pe-3",
      },
      cellProps: {
        className: "text-center fs-0 py-2",
      },
      Cell: (rowData) => `${formatDateTime(rowData.row.original.created_at)}`,
    },
    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: (rowData) => {
        const { id, username } = rowData.row.original;
        const handleDelete = async (e) => {
          const { isConfirmed } = await Swal.fire({
            title: t("sure", { ns: "common" }),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: t("cancel", { ns: "common" }),
            confirmButtonText: `${t("yes", { ns: "common" })}, ${t(
              "deleteConfirm",
              { ns: "common" }
            )}`,
          });
          if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/trainer/trainer/${id}/`).then(() => {
              toast.success(
                `${t("title")} ${username} ${t("deleteSuccess", {
                  ns: "common",
                })}.`,
                {
                  theme: "colored",
                }
              );
              fetchTeachers();
            });
            setLoading(false);
          }
        };
        return (
          <CardDropdown>
            <div className="py-2">
              {hasPermission("trainer.change_trainer") && (
                <>
                  <Dropdown.Item
                    onClick={() => navigate(paths.teacherEdit(id))}
                  >
                    {t("edit", { ns: "common" })}
                  </Dropdown.Item>
                  <Dropdown.Divider as="div" />
                </>
              )}
              {hasPermission("trainer.delete_trainer") && (
                <Dropdown.Item
                  onClick={(e) => handleDelete(e)}
                  className="text-danger"
                >
                  {t("delete", { ns: "common" })}
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  const teacherList = teachers.map((user) => ({
    id: user.id,
    name: user.name,
    title: user.title,
    organization: user.organization,
    created_at: user.created_at,
    full_name: user.full_name,
  }));

  const handleSort = (column) => {
    if (column.canSort) {
      const { id } = column;
      const isDescending = sortingField === id && sortingDirection === "desc";

      // Update the sorting field and direction
      setSortingField(id);
      setSortingDirection(isDescending ? "asc" : "desc");

      // Modify the sorting properties of the column
      column.isSorted = true;
      column.isSortedDesc = isDescending;
      column.sortingDirection = isDescending ? "asc" : "desc";
    }
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={teacherList}
      selection
      pagination
      perPage={20}
    >
      <Card className="mb-3">
        <Card.Header>
          <BaseTableHeader
            table
            fetchData={delayedLoadItems}
            data={teacherList}
            count={count}
            addPermission={"trainer.add_trainer"}
            creationPath={paths.teacherCreate}
            title={"teacher"}
            setLoading={setLoading}
          />
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            <Flex justifyContent="center" className="p-2 mb-2">
              <Spinner animation={"border"} variant={"primary"} />
            </Flex>
          ) : (
            <AdvanceTable
              handleSort={handleSort}
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
              headers={columns}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            query={query}
            fetch={fetchTeachers}
            count={count}
            length={length}
            itemsPerPage={20}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default withPermission(Teachers, "trainer.view_trainer");
