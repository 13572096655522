import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import { api } from "../../../utils/api";
import paths from "../../../routes/paths";
import { formatDateTime, getSize } from "../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";
import SimpleBarReact from "simplebar-react";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import CardDropdown from "../../../components/common/CardDropdown";
import IconButton from "../../../components/common/IconButton";
import { toast } from "react-toastify";
import FormError from "../../errors/FormError";

const GigPurchase = () => {
  const [gig, setGig] = useState({});
  const [loading, setLoading] = useState(false);
  const [additionalFee, setAdditionalFee] = useState(0);
  const [clientFee, setClientFee] = useState(0);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const [formData, setFormData] = useState({
    cover_letter: "",
    requested_payment_terms: 0,
    teacher: "",
    attachments: [],
    job: id,
  });

  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles) => {
      const fileReaders = acceptedFiles.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve({
              id: uuid(),
              file: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
              data: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      Promise.all(fileReaders)
        .then((newFiles) => {
          setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, ...newFiles];
            setFormData({ ...formData, attachments: updatedFiles });
            return updatedFiles;
          });
        })
        .catch((error) => {
          console.error("Error reading files: ", error);
        });
    },
  });

  const handleRemove = (path) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(
        (file) => file?.data?.path !== path
      );
      setFormData({ ...formData, attachments: updatedFiles });
      return updatedFiles;
    });
  };

  const fetchData = async () => {
    setLoading(true);
    await api
      .get(`/gig/feed/${id}/`)
      .then((res) => setGig(res?.data))
      .catch((error) => {
        if (error?.response?.status === 404) {
          navigate(paths.error404);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAdditionalFee((clientFee * 15) / 100 || 0);
  }, [clientFee]);

  useEffect(() => {
    setFormData({
      ...formData,
      requested_payment_terms: clientFee - additionalFee,
    });
    // eslint-disable-next-line
  }, [clientFee, additionalFee]);

  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await api
      .post("/gig/proposal/", { ...formData, job: id })
      .then((res) => {
        toast.success("Your application successfully saved.", {
          theme: "colored",
        });
        navigate(paths.feed);
      })
      .catch((err) => {
        toast.error("An error has occurred.", {
          theme: "colored",
        });
        setErrors(err?.response?.data);
      });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : (
    <Fragment>
      <h3>Submit a proposal</h3>
      <Row>
        <Col>
          <Card>
            <Card.Header as={"h5"}>Job details</Card.Header>
            <Card.Body>
              <Row>
                <Col lg={9}>
                  <h5>{gig?.title}</h5>
                  <p className={"text-muted"}>
                    Posted {formatDateTime(gig?.created_at)}
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: gig?.description }}></p>
                  <hr />
                  <h5>Skills and expertise</h5>
                  {gig?.required_skills}
                </Col>
                <Col lg={3}>
                  <p className={"fs-9"}>
                    <FontAwesomeIcon icon={faClock} className={"me-2"} />
                    Hourly rate: ${gig?.hourly_rate}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>Terms</Card.Header>
            <Card.Body>
              <Flex justifyContent={"between"}>
                <h5>What is the rate you'd like to bid for this job?</h5>
                <p>Client Budget: ${gig?.hourly_rate}/hr</p>
              </Flex>

              <SimpleBarReact>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>Hourly Rate</td>
                      <td>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder={"Hourly Rate"}
                            value={clientFee}
                            onChange={(e) =>
                              setClientFee(parseFloat(e.target.value))
                            }
                          />
                          <InputGroup.Text>/hr</InputGroup.Text>
                        </InputGroup>
                        <FormError error={errors?.requested_payment_terms} />
                      </td>
                    </tr>
                    <tr>
                      <td>15% Shortcut Fee</td>
                      <td className={"text-end"}>${additionalFee}</td>
                    </tr>
                    <tr>
                      <td>You'll Receive</td>
                      <td>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder={"You'll Receive"}
                            name={"requested_payment_terms"}
                            readOnly
                            value={formData.requested_payment_terms}
                            onChange={handleFieldChange}
                          />
                          <InputGroup.Text>/hr</InputGroup.Text>
                        </InputGroup>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </SimpleBarReact>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>Cover Letter</Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Control
                  as={"textarea"}
                  rows={5}
                  name={"cover_letter"}
                  onChange={handleFieldChange}
                />
                <FormError error={errors?.cover_letter} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Attachments</Form.Label>
                <div {...getRootProps({ className: "dropzone-area py-6" })}>
                  <input name="uploadedFiles" {...getInputProps()} />
                  <Flex justifyContent="center">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                    <p className="fs-9 mb-0 text-700">Drop your files here</p>
                  </Flex>
                </div>
                <div>
                  {files?.map((file) => (
                    <Flex
                      alignItems="center"
                      className="py-3 border-bottom btn-reveal-trigger"
                      key={file?.data?.path}
                    >
                      <img
                        className="rounded"
                        width={40}
                        height={40}
                        src={file?.data?.preview || file?.image_url}
                        alt={file?.data?.path || file?.image_url}
                      />

                      <Flex
                        justifyContent="between"
                        alignItems="center"
                        className="ms-3 flex-1"
                      >
                        <div>
                          <h6>{file?.data?.path}</h6>
                          <Flex
                            className="position-relative"
                            alignItems="center"
                          >
                            <p className="mb-0 fs-10 text-400 line-height-1">
                              <strong>{getSize(file?.data?.size)}</strong>
                            </p>
                          </Flex>
                        </div>
                      </Flex>
                      <CardDropdown>
                        <div className="py-2">
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => handleRemove(file?.data?.path)}
                          >
                            Remove
                          </Dropdown.Item>
                        </div>
                      </CardDropdown>
                    </Flex>
                  ))}
                </div>
                <Form.Text>
                  You may attach files including work samples or other documents
                  to support your application. Do not attach your resume, your
                  profile is automatically forwarded to the client with your
                  proposal.
                </Form.Text>
                <FormError error={errors?.attachments} />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Flex
                justifyContent={"between"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <span>
                  <ul>
                    <li className={"text-decoration-none text-danger"}>
                      * Mandatory Field
                    </li>
                  </ul>
                </span>
                <IconButton icon={faSave} onClick={(e) => handleSubmit(e)}>
                  <span className="d-none d-sm-inline-block ms-1">
                    Submit proposal
                  </span>
                </IconButton>
              </Flex>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GigPurchase;
