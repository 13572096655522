import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { useAppContext } from "providers/AppProvider";

const TinymceEditor = ({
  value,
  handleChange,
  height = "50vh",
  isInvalid,
  name,
  plugins,
}) => {
  const {
    config: { isDark, isRTL },
    getThemeColor,
  } = useAppContext();
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dom.addStyle(`
        .mce-content-body {
          color: ${getThemeColor("emphasis-color")} !important;
          background-color: ${getThemeColor("tinymce-bg")} !important;
        }`);
    }
    // eslint-disable-next-line
  }, [isDark]);

  return (
    <div className={classNames({ "is-invalid": isInvalid })}>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={handleChange}
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        init={{
          height,
          menubar: false,
          content_style: `
            .mce-content-body {
              color: ${getThemeColor("emphasis-color")};
              background-color: ${getThemeColor("tinymce-bg")};
            }`,
          statusbar: false,
          plugins: `${plugins} image lists table media directionality`,
          toolbar:
            "styleselect | bold italic link bullist numlist image blockquote table media undo redo",

          directionality: isRTL ? "rtl" : "ltr",
          theme_advanced_toolbar_align: "center",
        }}
        textareaName={name}
      />
    </div>
  );
};

TinymceEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string,
  isInvalid: PropTypes.bool,
};

export default TinymceEditor;
