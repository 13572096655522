import React from "react";
import Flex from "components/common/Flex";
import RegistrationForm from "pages/authentication/RegistrationForm";
import bgImg from "assets/img/generic/15.jpg";
import AuthSplitLayout from "layouts/AuthSplitLayout";
import { withTranslation } from "react-i18next";

const Registration = ({ t, i18n }) => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Registration</h3>
      </Flex>
      <RegistrationForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(Registration);
