import CertificationForm from "./CertificationForm";
import React from "react";
import EducationForm from "./EducationForm";

const DegreeForm = ({ values, errors, setFieldValue }) => {
  return (
    <>
      <EducationForm
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
      />
      <CertificationForm
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

export default DegreeForm;
