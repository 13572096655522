import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../providers/AppProvider";
import { usePermissions } from "../../providers/PermissionsProvider";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../routes/paths";
import { api } from "../../utils/api";
import { debounce } from "lodash";
import Flex from "../../components/common/Flex";
import SoftBadge from "../../components/common/SoftBadge";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {formatDateTime, withPermission} from "../../helpers/utils";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import { Card, Dropdown, Spinner } from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../BaseTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";

const GigList = () => {
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortingField, setSortingField] = useState("created_at"); // Set the initial sorting field
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const query = useQuery();

  const { t } = useTranslation(["users", "common"]);

  const { setConfig } = useAppContext();
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();

  const fetchGigs = async (q) => {
    setLoading(true);
    const sortingParam = `${
      sortingDirection === "desc" ? "-" : ""
    }${sortingField}`;

    query.set("ordering", sortingParam);

    // Cancel the previous request if it exists
    await api
      .get(`/gig/gig/?${query.toString()}`)
      .then((res) => {
        setGigs(res?.data?.results);
        setCount(res?.data?.count);
      })
      .catch((error) => {
        if (error?.response?.status === 404) navigate(paths.error404);
        if (error?.response?.status === 500) navigate(paths.error500);
        if (error?.response?.status === 403)
          setConfig("isAuthenticated", false);
      });
    setLength(gigs.length);
    setLoading(false);
  };

  // eslint-disable-next-line
  const delayedLoadItems = useCallback(debounce(fetchGigs, 600), []);

  useEffect(() => {
    fetchGigs(query);
    setLength(gigs.length);
    navigate(`?${query.toString()}`);
    // eslint-disable-next-line
  }, [sortingField, sortingDirection]);

  const columns = [
    {
      accessor: "title",
      Header: t("fields.title"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { id, title } = rowData.row.original;
        return (
          <Link to={paths.gigDetail(id)}>
            <Flex alignItems="center">
              <div className="flex-1">
                <h6
                  className="mb-0 fs-0"
                  style={{
                    width: "200px",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {title}
                </h6>
              </div>
            </Flex>
          </Link>
        );
      },
    },
    {
      accessor: "is_active",
      Header: t("fields.status.title"),
      canSort: true,
      headerProps: { className: "pe-1 text-center" },
      cellProps: {
        className: "py-2 fs-0",
      },
      sortingDirection: sortingField === "is_active" && sortingDirection,
      isSorted: false,
      Cell: (rowData) => {
        const { is_active } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: is_active,
              danger: !is_active,
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0">
              {is_active && t("fields.status.active")}
              {!is_active && t("fields.status.inactive")}
            </p>
            <FontAwesomeIcon
              icon={classNames({
                check: is_active,
                ban: !is_active,
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      accessor: "created_at",
      Header: t("fields.created_at"),
      canSort: true,
      sortingDirection: sortingField === "created_at" && sortingDirection,
      isSorted: false,
      headerProps: {
        className: "text-center pe-3",
      },
      cellProps: {
        className: "text-center fs-0 py-2",
      },
      Cell: (rowData) => `${formatDateTime(rowData.row.original.created_at)}`,
    },
    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: (rowData) => {
        const { id, title } = rowData.row.original;
        const handleDelete = async (e) => {
          const { isConfirmed } = await Swal.fire({
            title: t("sure", { ns: "common" }),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: t("cancel", { ns: "common" }),
            confirmButtonText: `${t("yes", { ns: "common" })}, ${t(
              "deleteConfirm",
              { ns: "common" }
            )}`,
          });
          if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/gig/gig/${id}/`).then(() => {
              toast.success(
                `${t("title")} ${title} ${t("deleteSuccess", {
                  ns: "common",
                })}.`,
                {
                  theme: "colored",
                }
              );
              fetchGigs(query);
            });
            setLoading(false);
          }
        };
        return (
          <CardDropdown>
            <div className="py-2">
              {hasPermission("gig.change_gig") && (
                <>
                  <Dropdown.Item onClick={() => navigate(paths.gigEdit(id))}>
                    {t("edit", { ns: "common" })}
                  </Dropdown.Item>
                  <Dropdown.Divider as="div" />
                </>
              )}
              {hasPermission("gig.delete_gig") && (
                <Dropdown.Item
                  onClick={(e) => handleDelete(e)}
                  className="text-danger"
                >
                  {t("delete", { ns: "common" })}
                </Dropdown.Item>
              )}
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  const gigList = gigs?.map((gig) => ({
    id: gig.id,
    title: gig.title,
    is_active: gig.is_active,
    created_at: gig.created_at,
  }));

  const handleSort = (column) => {
    if (column.canSort) {
      const { id } = column;
      const isDescending = sortingField === id && sortingDirection === "desc";

      // Update the sorting field and direction
      setSortingField(id);
      setSortingDirection(isDescending ? "asc" : "desc");

      // Modify the sorting properties of the column
      column.isSorted = true;
      column.isSortedDesc = isDescending;
      column.sortingDirection = isDescending ? "asc" : "desc";
    }
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={gigList}
      selection
      pagination
      perPage={20}
    >
      <Card className="mb-3">
        <Card.Header>
          <BaseTableHeader
            table
            fetchData={delayedLoadItems}
            data={gigList}
            count={count}
            addPermission={"gig.view_teachergig" || "gig.view_mentorshipjob"}
            creationPath={paths.gigCreate}
            title={"gigs"}
            setLoading={setLoading}
          />
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            <Flex justifyContent="center" className="p-2 mb-2">
              <Spinner animation={"border"} variant={"primary"} />
            </Flex>
          ) : (
            <AdvanceTable
              handleSort={handleSort}
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
              headers={columns}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            query={query}
            fetch={fetchGigs}
            count={count}
            length={length}
            itemsPerPage={20}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default withPermission(GigList, ["gig.view_mentorshipjob", "gig.view_teachergig"]);
