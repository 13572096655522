import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import CourseGrid from "../../e-learning/course/CourseGrid";

const TrainerCourses = ({ trainer, courses }) => {
  return (
    <Card>
      <Card.Header
        as={Flex}
        className="flex-between-center bg-body-tertiary py-2"
      >
        <h6 className="mb-0">
          Other Courses by {trainer?.user_data?.full_name}
        </h6>
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          {courses?.map((course) => (
            <Col md={6} xxl={4} key={course.id}>
              <CourseGrid course={course} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TrainerCourses;
