import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../providers/AppProvider";
import { api } from "../../../utils/api";
import paths from "../../../routes/paths";
import {
  Button,
  Card,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import Avatar from "../../../components/common/Avatar";
import Slider from "react-slick";
import DropdownFilter from "../../../components/common/DropdownFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import StarRating from "../../../components/common/StarRating";

const FeedDetail = () => {
  const [gig, setGig] = useState({});
  const [loading, setLoading] = useState(false);
  const [calendarApi, setCalendarApi] = useState({});
  const [title, setTitle] = useState("");
  const [currentFilter, setCurrentFilter] = useState("Month View");
  const [events, setEvents] = useState([]);

  const calendarRef = useRef();
  const { id } = useParams();
  const {
    config: { isStudent, isRTL },
  } = useAppContext();
  const navigate = useNavigate();

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const viewName = [
    "Month View",
    "Week View",
    "Day View",
    "List View",
    "Year View",
  ];

  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    omitZeroMinute: true,
    meridiem: true,
  };

  const handleFilter = (filter) => {
    if (!calendarApi) return;
    setCurrentFilter(filter);
    switch (filter) {
      case "Month View":
        calendarApi.changeView("dayGridMonth");
        break;
      case "Week View":
        calendarApi.changeView("timeGridWeek");
        break;
      case "Day View":
        calendarApi.changeView("timeGridDay");
        break;
      case "List View":
        calendarApi.changeView("listWeek");
        break;
      default:
        calendarApi.changeView("listYear");
    }
    setTitle(calendarApi.getCurrentData().viewTitle);
  };

  const handleSlotSelect = (info) => {
    const selectedSlot = {
      start: info.startStr,
      end: info.endStr,
    };
    console.log("Selected Slot:", selectedSlot);
    // Optionally update state or send data to backend
  };

  const fetchData = async () => {
    setLoading(true);
    await api
      .get(`/gig/feed/${id}/`)
      .then((res) => setGig(res?.data))
      .catch((err) => {
        if (err?.response?.status === 404) navigate(paths.error404);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      setCalendarApi(api);
      setTitle(api.getCurrentData().viewTitle);
    }
  }, []);

  useEffect(() => {
    setEvents(
      gig?.schedule_data?.map((s) => ({
        start: s?.start_time,
        end: s?.end_time,
        max_participants: s?.max_participants,
        allDay: true,
        color: "red",
      }))
    );
  }, [gig?.schedule_data]);

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={"border"} variant={"primary"} />
    </Flex>
  ) : isStudent ? (
    <Fragment>
      <Row>
        <Col lg={7}>
          <Card>
            <Card.Header as={"h5"} className={"text-black"}>
              {gig.title}
              <Row className={"mt-3"}>
                <Col lg={2}>
                  <Avatar src={gig?.teacher_avatar} size={"3xl"} />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <p className={"fs-9"}>
                        {gig?.teacher_full_name}{" "}
                        <span className={"text-muted fs-11"}>
                          0 order in queue
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <div className="position-relative h-sm-auto overflow-hidden">
                <Slider
                  {...sliderSettings}
                  className="slick-slider-arrow-inner"
                >
                  {gig?.images_data?.map((img, index) => {
                    return (
                      <Image
                        fluid
                        rounded
                        src={img?.image_url}
                        alt={`Photo ${index + 1}`}
                        key={index}
                      />
                    );
                  })}
                </Slider>
              </div>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>About this Session</Card.Header>
            <Card.Body
              dangerouslySetInnerHTML={{ __html: gig?.description }}
            ></Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>Agenda</Card.Header>
            <Card.Body
              dangerouslySetInnerHTML={{ __html: gig?.agenda }}
            ></Card.Body>
          </Card>
        </Col>
        <Col lg={5}>
          <div className={"sticky"}>
            <Card>
              <Card.Header as={"h5"}>Schedule</Card.Header>
              <Card.Body>
                <Row className="align-items-center gx-0 mb-3">
                  <Col
                    xs="auto"
                    className="d-flex justify-content-end order-md-1"
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip style={{ position: "fixed" }} id="nextTooltip">
                          Previous
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="link"
                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                        onClick={() => {
                          calendarApi.prev();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                        }}
                      >
                        <FontAwesomeIcon icon="arrow-left" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          style={{ position: "fixed" }}
                          id="previousTooltip"
                        >
                          Next
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="link"
                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                        onClick={() => {
                          calendarApi.next();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                        }}
                      >
                        <FontAwesomeIcon icon="arrow-right" />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col
                    xs="auto"
                    className="d-flex justify-content-end order-md-2"
                  >
                    <h4 className="mb-0 fs--1">
                      {title ||
                        `${calendarApi.currentDataManager?.data?.viewTitle}`}
                    </h4>
                  </Col>
                  <Col
                    xs
                    md="auto"
                    className="d-flex justify-content-end order-md-3"
                  >
                    <Button
                      size="sm"
                      variant="falcon-primary"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.getCurrentData().viewTitle);
                      }}
                    >
                      Today
                    </Button>
                  </Col>
                  <Col md="auto" className="d-md-none">
                    <hr />
                  </Col>
                  <Col className="d-flex justify-content-end order-md-2">
                    <DropdownFilter
                      className="me-2"
                      filters={viewName}
                      currentFilter={currentFilter}
                      handleFilter={handleFilter}
                      icon="sort"
                      right
                    />
                  </Col>
                </Row>
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={false}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                  ]}
                  initialView="dayGridMonth"
                  themeSystem="bootstrap"
                  dayMaxEvents={2}
                  direction={isRTL ? "rtl" : "ltr"}
                  height={530}
                  stickyHeaderDates={false}
                  editable={false}
                  selectable={true} // Enable selection
                  selectMirror={true}
                  select={handleSlotSelect} // Handle selection
                  eventTimeFormat={eventTimeFormat}
                  events={events} // Preloaded session data
                />
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </div>
        </Col>
        <Col lg={12}>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>
              Get to know {gig?.teacher_full_name}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={2} className={"text-center"}>
                  <Avatar src={gig?.teacher_avatar} size={"4xl"} />
                </Col>
                <Col lg={10}>
                  <h5>{gig?.teacher_full_name}</h5>
                  <p>{gig?.teacher_data?.user_data?.heading}</p>
                  <p>
                    <StarRating
                      rating={gig?.avg_reviews}
                      readonly
                      fractions={2}
                      className="me-2"
                    />
                    {gig?.avg_reviews} ({gig?.count_reviews})
                  </p>
                  <Link
                    as={Button}
                    variant={"outline-primary"}
                    to={`mailto:${gig?.teacher_data?.user_data?.email}`}
                  >
                    Contact me
                  </Link>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col
                  dangerouslySetInnerHTML={{
                    __html: gig?.teacher_data?.user_data?.introduction,
                  }}
                ></Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header as={"h5"}>Reviews</Card.Header>
            <Card.Body>
              <Row>
                <Col>{gig?.count_reviews} reviews for this session</Col>
                <Col className={"text-end"}>
                  <StarRating
                    rating={gig?.avg_reviews}
                    readonly
                    fractions={2}
                    className="me-2"
                  />
                  {gig?.avg_reviews}
                </Col>
              </Row>
              <Row className={"mt-2"}></Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
};

export default FeedDetail;
