import paths from "./paths";
import {
  faChalkboardTeacher,
  faClipboardList,
  faShareAlt,
  faUserGraduate,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { getItemFromStore } from "../helpers/utils";

const group = getItemFromStore("group", "student");

export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  permissions: ["dashboard.view_dashboard"],
  children: [
    {
      name: "Dashboard",
      active: true,
      icon: "chart-pie",
      to: paths.lms,
      permission: "dashboard.view_dashboard",
    },
  ],
};
export const appRoutes = {
  label: "app",
  children: [
    {
      name: "Accounts",
      active: true,
      icon: "user",
      permissions: ["accounts.view_user", "auth.view_group"],
      children: [
        {
          name: "Users",
          active: true,
          icon: faUsersCog,
          to: paths.userList,
          permission: "accounts.view_user",
        },
        {
          name: "Groups",
          active: true,
          icon: "users",
          to: paths.groupList,
          permission: "auth.view_group",
        },
      ],
    },
    {
      name: "Courses",
      to: paths.courses("course-list"),
      active: true,
      icon: "graduation-cap",
      permission: "course.view_course",
    },
    {
      name:
        group === process.env.REACT_APP_TEACHER_GROUP_NAME
          ? "Sessions"
          : "Requests",
      to: paths.gigList,
      active: true,
      icon: faClipboardList,
      permission: group === process.env.REACT_APP_TEACHER_GROUP_NAME
          ? "gig.view_teachergig"
          : "gig.view_mentorshipjob",
    },
    {
      name: "Feed",
      to: paths.feed,
      active: true,
      icon: faShareAlt,
      permission: group === process.env.REACT_APP_TEACHER_GROUP_NAME
          ? "gig.view_mentorshipjob"
          : "gig.view_teachergig",
    },
    {
      name: "Teachers",
      to: paths.teacherList,
      active: true,
      icon: faChalkboardTeacher,
      permission: "trainer.view_trainer",
    },
    {
      name: "Students",
      to: paths.studentList,
      active: true,
      icon: faUserGraduate,
      permission: "student.view_student",
    },
  ],
};
// eslint-disable-next-line
export default [dashboardRoutes, appRoutes];
