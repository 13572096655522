import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import { Card, Dropdown } from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import { getSize } from "../../../helpers/utils";
import CardDropdown from "../../../components/common/CardDropdown";

const GigUpload = ({ values, setFieldValue, errors }) => {
  const [files, setFiles] = useState(values?.images || []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop: (acceptedFiles) => {
      const fileReaders = acceptedFiles.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve({
              id: uuid(),
              file: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
              data: {
                base64: reader.result,
                path: file.path,
                size: file.size,
                preview: URL.createObjectURL(file),
              },
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      Promise.all(fileReaders)
        .then((newFiles) => {
          setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, ...newFiles];
            setFieldValue("images", updatedFiles);
            return updatedFiles;
          });
        })
        .catch((error) => {
          console.error("Error reading files: ", error);
        });
    },
  });

  const handleRemove = (path) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter(
        (file) => file?.data?.path !== path
      );
      setFieldValue("images", updatedFiles);
      return updatedFiles;
    });
  };

  return (
    <Card className={"mb-3"}>
      <Card.Header as={"h5"}>Images</Card.Header>
      <Card.Body>
        <div {...getRootProps({ className: "dropzone-area py-6" })}>
          <input name="uploadedFiles" {...getInputProps()} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-9 mb-0 text-700">Drop your images here</p>
          </Flex>
        </div>
        <div>
          {files?.map((file) => (
            <Flex
              alignItems="center"
              className="py-3 border-bottom btn-reveal-trigger"
              key={file?.data?.path}
            >
              <img
                className="rounded"
                width={40}
                height={40}
                src={file?.data?.preview || file?.image_url}
                alt={file?.data?.path || file?.image_url}
              />

              <Flex
                justifyContent="between"
                alignItems="center"
                className="ms-3 flex-1"
              >
                <div>
                  <h6>{file?.data?.path}</h6>
                  <Flex className="position-relative" alignItems="center">
                    <p className="mb-0 fs-10 text-400 line-height-1">
                      <strong>{getSize(file?.data?.size)}</strong>
                    </p>
                  </Flex>
                </div>
              </Flex>
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => handleRemove(file?.data?.path)}
                  >
                    Remove
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default GigUpload;
