import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Section from "components/common/Section";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import Services from "./Services";
import Flex from "../../components/common/Flex";
import paths from "../../routes/paths";

const Hero = ({ t, i18n }) => {
  return (
    <Section
      className="py-0 overflow-hidden light"
      position="center"
      overlay
      fluid
    >
      <div className={"ellipse"}></div>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        wrap={"wrap"}
        className="pt-5 pt-lg-8 pb-lg-6 pb-xl-0"
      >
        <div className="pb-7 pe-4 pb-xl-9 text-center text-xl-start">
          <h1 className="text-primary text-wrap fw-bold mt-2">
            Apprenez plu vite <br />
            Grace aux meilleurs cours{" "}
          </h1>
          <Button
            as={Link}
            variant="primary"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to={`${paths.cardLogin}`}
          >
            Get Started
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </div>
        <Link to="/" className={"d-flex justify-content-center"}>
          <img className="img-fluid computer" src={""} alt="" />
        </Link>
      </Flex>

      <Services t={t} i18n={i18n} />
    </Section>
  );
};

export default Hero;
