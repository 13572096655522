import { Button, Card, Col, Image, Row } from "react-bootstrap";
import Hoverbox from "../../components/common/Hoverbox";
import Flex from "../../components/common/Flex";
import React from "react";
import { formatDateTime } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
import paths from "../../routes/paths";

const TeacherGigList = ({ gig }) => {
  const {
    id,
    negotiable_rate,
    hourly_rate,
    title,
    created_at,
    description,
  } = gig;

  const navigate = useNavigate();

  return (
    <Card className="overflow-hidden">
      <Card.Body className="p-0">
        <Row className="g-0">
          <Col md={4} lg={3}>
            <Hoverbox className="h-md-100">
              <Button variant="link" className="p-0 border-0 h-100">
                <Image src={""} alt="" className="w-100 h-100 fit-cover" />
              </Button>
            </Hoverbox>
          </Col>
          <Col md={8} lg={9} className="p-x1">
            <Row className="g-0 h-100">
              <Col lg={8} as={Flex} className="flex-column pe-x1">
                <Flex wrap="wrap" className="gap-2 mb-3">
                  {/*{tags.map((tag) => (*/}
                  {/*  <SubtleBadge key={tag.id} pill bg={tag.type}>*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      icon={tag.icon}*/}
                  {/*      className="me-1"*/}
                  {/*      transform="shrink-4"*/}
                  {/*    />*/}
                  {/*    {tag.content}*/}
                  {/*  </SubtleBadge>*/}
                  {/*))}*/}
                </Flex>
                <h4 className="mt-3 mt-sm-0 fs-9 fs-lg-8">{title}</h4>
                <p className={"text-muted fs-11"}>
                  {formatDateTime(created_at)}
                </p>
                <p
                  className="fs-10 mt-2 d-none d-lg-block"
                  dangerouslySetInnerHTML={{
                    __html: `${description.substring(0, 142)}...`,
                  }}
                ></p>
              </Col>
              <Col lg={4} className="mt-4 mt-lg-0">
                <Flex
                  justifyContent="between"
                  className="h-100 rounded border-lg border-1 flex-lg-column p-lg-3"
                >
                  <div className="mb-lg-4 mt-auto mt-lg-0">
                    <h4 className="fs-8 text-warning d-flex align-items-center">
                      <span>${hourly_rate}</span>
                    </h4>
                    <p className="mb-0 fs-10 text-800">
                      {negotiable_rate ? "Negotiable" : "Non negotiable"}
                    </p>
                  </div>
                  <Flex className="mt-3 flex-lg-column gap-2">
                    <Button
                      size="md"
                      variant="primary"
                      className="fs-10"
                      onClick={() => navigate(paths.gigPurchase(id))}
                    >
                      <span className="ms-1 d-none d-lg-inline text-uppercase">
                        Apply now
                      </span>
                    </Button>
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TeacherGigList;
