import { withTranslation } from "react-i18next";
import AuthSplitLayout from "../../../layouts/AuthSplitLayout";
import bgImg from "../../../assets/img/generic/20.jpg";
import Flex from "../../../components/common/Flex";
import React from "react";
import PaymentMethodForm from "../PaymentMethodForm";

const StudentPaymentMethod = ({ t, i18n }) => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Payment Method</h3>
      </Flex>
      <PaymentMethodForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(
  StudentPaymentMethod
);
