import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import NavbarTop from "components/navbar/top/NavbarTop";
import NavbarVertical from "components/navbar/vertical/NavbarVertical";

import { useAppContext } from "providers/AppProvider";
import paths from "../routes/paths";
import CourseProvider from "../providers/CourseProvider";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition, isAuthenticated },
  } = useAppContext();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!isAuthenticated) return <Navigate to={paths.cardLogin} replace />;

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
      <CourseProvider>
        <div className={classNames("content", { "pb-0": isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          {/*{!isKanban && <Footer />}*/}
        </div>
      </CourseProvider>
    </div>
  );
};

export default MainLayout;
