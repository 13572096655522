export const rootPaths = {
  root: "/",
  dashboardRoot: "dashboard",
  authRoot: "authentication",
  authCardRoot: "card",
  authSplitRoot: "split",
  errorsRoot: "errors",
  coursesRoot: "courses",
  gigRoot: "gig",
  feedRoot: "feed",
  accountRoot: "account",
  userRoot: "user",
  groupRoot: "group",
  teacherRoot: "teacher",
  studentRoot: "student",
};
// eslint-disable-next-line
export default {
  error404: `/${rootPaths.errorsRoot}/404`,
  error500: `/${rootPaths.errorsRoot}/500`,
  cardLogin: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/login`,
  cardRegister: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/register`,
  cardTeach: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/teach`,
  cardLogout: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/logout`,
  cardForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/forgot-password`,
  cardResetPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/reset-password`,
  cardConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/confirm-mail`,
  cardLockScreen: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/lock-screen`,
  cardTeacherSignup: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/teacher-signup`,
  splitStudentContact: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/student-contact`,
  splitStudentStudy: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/student-field`,
  splitStudentPaymentMethod: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/student-payment`,
  lms: `/${rootPaths.dashboardRoot}/lms`,
  createCourse: `/${rootPaths.coursesRoot}/new`,
  courses: (courseLayout) =>
    `/${rootPaths.coursesRoot}/course-details${
      courseLayout ? `/${courseLayout}` : ""
    }`,
  courseDetails: (courseId) =>
    `/${rootPaths.coursesRoot}${courseId ? `/${courseId}` : ""}`,
  studentOverview: `/${rootPaths.coursesRoot}/student-overview`,
  userProfile: `/${rootPaths.accountRoot}/profile`,
  userList: `/${rootPaths.accountRoot}/${rootPaths.userRoot}`,
  userCreate: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/new`,
  userEdit: (id) =>
    `/${rootPaths.accountRoot}/${rootPaths.userRoot}/${id ? id : ""}/edit`,
  userDetail: (id) =>
    `/${rootPaths.accountRoot}/${rootPaths.userRoot}/${id ? id : ""}/detail`,
  groupList: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}`,
  groupCreate: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}/new`,
  groupEdit: (id) =>
    `/${rootPaths.accountRoot}/${rootPaths.groupRoot}/${id ? id : ""}/edit`,
  teacherList: `/${rootPaths.teacherRoot}`,
  teacherCreate: `/${rootPaths.teacherRoot}/new`,
  teacherEdit: (id) => `/${rootPaths.teacherRoot}/${id}/edit`,
  teacherProfile: (id) => `/${rootPaths.teacherRoot}/${id}/detail`,
  studentList: `/${rootPaths.studentRoot}`,
  studentCreate: `/${rootPaths.studentRoot}/new`,
  studentEdit: (id) => `/${rootPaths.studentRoot}/${id}/edit`,
  studentProfile: (id) => `/${rootPaths.studentRoot}/${id}/detail`,
  feed: `/${rootPaths.feedRoot}`,
  feedDetail: (id) => `/${rootPaths.feedRoot}/${id}/detail`,
  gigPurchase: (id) => `/${rootPaths.feedRoot}/${id}`,
  gigList: `/${rootPaths.gigRoot}`,
  gigCreate: `/${rootPaths.gigRoot}/new`,
  gigDetail: (id) => `/${rootPaths.gigRoot}/${id}/detail`,
  gigEdit: (id) => `/${rootPaths.gigRoot}/${id}/edit`,
};
