import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import Flex from "../../components/common/Flex";
import { Link } from "react-router-dom";
import paths from "../../routes/paths";
import Slider from "react-slick";
import Avatar from "../../components/common/Avatar";

const GigGrid = ({ gig }) => {
  const {
    id,
    title,
    teacher,
    teacher_full_name,
    description,
    teacher_avatar,
    images_data,
    hourly_rate,
  } = gig;
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Card className="h-100 overflow-hidden">
        <Card.Body
          as={Flex}
          direction="column"
          justifyContent="between"
          className="p-0"
        >
          <div>
            {images_data && (
              <div className="position-relative h-sm-auto overflow-hidden">
                <Slider
                  {...sliderSettings}
                  className="slick-slider-arrow-inner"
                >
                  {images_data?.map((img, index) => {
                    return (
                      <Image
                        fluid
                        rounded
                        src={img?.image_url}
                        alt={`Photo ${index + 1}`}
                        key={index}
                      />
                    );
                  })}
                </Slider>
              </div>
            )}
            <div className="p-3">
              <h5 className="fs-9 mb-2">
                <Link to={paths.feedDetail(id)} className="text-1100">
                  {title}
                </Link>
              </h5>
              <h5 className="fs-10">
                <Link to={paths.teacherProfile(teacher)}>
                  <Avatar src={teacher_avatar} size={"s"} className={"me-2"} />
                  {teacher_full_name}
                </Link>
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${description.slice(0, 143)}...`,
                }}
              ></div>
            </div>
          </div>
          <Row className="g-0 mb-3 align-items-end">
            <Col className="ps-3">
              <h4 className="fs-9 text-warning d-flex align-items-center">
                <span>From ${hourly_rate}</span>
              </h4>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GigGrid;
