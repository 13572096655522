import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Field } from "formik";
import TinymceEditor from "../../../components/common/TinymceEditor";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from "react-datepicker";
import { api } from "../../../utils/api";
import Select from "react-select";

const StudentGigForm = ({ errors, setFieldValue, values }) => {
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const getSkills = async () => {
    await api
      .get("/gig/skill/")
      .then((res) => setSkills(res?.data?.results))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSkills();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h5>Basic Information</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>
                  Title <span className={"text-danger"}>*</span>
                </Form.Label>
                <Field name={"title"}>
                  {({ field }) => (
                    <Form.Control
                      type={"text"}
                      placeholder={"Title"}
                      value={field.value}
                      onChange={(e) =>
                        setFieldValue(field.name, e.target.value)
                      }
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Description<span className="text-danger">*</span>
                </Form.Label>
                <Field name={"description"}>
                  {({ field }) => (
                    <div className="create-course-description-textarea">
                      <TinymceEditor
                        height="13.438rem"
                        value={field.value}
                        handleChange={(newValue) => {
                          setFieldValue(field.name, newValue);
                        }}
                      />
                    </div>
                  )}
                </Field>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Timeline <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"project_timeline"}>
                      {({ field }) => (
                        <DatePicker
                          selected={
                            values?.project_timeline
                              ? new Date(values?.project_timeline)
                              : null
                          }
                          defaultValue=""
                          onChange={(newDate) => {
                            setFieldValue(
                              field.name,
                              new Date(newDate).toISOString()
                            );
                          }}
                          dateFormat="MM/dd/yyyy HH:mm"
                          fixedHeight
                          showTimeSelect
                          className="form-control"
                          customInput={
                            <CustomDateInput
                              formControlProps={{
                                placeholder: "MM/dd/yyyy HH:mm",
                              }}
                            />
                          }
                        />
                      )}
                    </Field>
                    <Form.Text>
                      When this post will be removed from the public feed
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Application Deadline{" "}
                      <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"application_deadline"}>
                      {({ field }) => (
                        <DatePicker
                          selected={
                            values?.application_deadline
                              ? new Date(values?.application_deadline)
                              : null
                          }
                          defaultValue=""
                          onChange={(newDate) => {
                            setFieldValue(
                              field.name,
                              new Date(newDate).toISOString()
                            );
                          }}
                          dateFormat="MM/dd/yyyy HH:mm"
                          fixedHeight
                          showTimeSelect
                          className="form-control"
                          customInput={
                            <CustomDateInput
                              formControlProps={{
                                placeholder: "MM/dd/yyyy HH:mm",
                              }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Text>
                    When this post no longer accepting applications
                  </Form.Text>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>
                  Status <span className={"text-danger"}>*</span>
                </Form.Label>
                <Field name={"status"}>
                  {({ field }) => (
                    <Form.Select
                      value={field.value}
                      onChange={(e) =>
                        setFieldValue(field.name, e.target.value)
                      }
                    >
                      <option value={""}>-----------------------------</option>
                      <option value={"open"}>Open</option>
                      <option value={"closed"}>Closed</option>
                    </Form.Select>
                  )}
                </Field>
              </Form.Group>
              <Form.Text>Current status for this posting</Form.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h5>Payment Terms</h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Hourly Rate <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <Field name={"hourly_rate"}>
                      {({ field }) => (
                        <InputGroup className="mb-3">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder={"Hourly Rate"}
                            value={field.value}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.value)
                            }
                          />
                          <InputGroup.Text>/hr</InputGroup.Text>
                        </InputGroup>
                      )}
                    </Field>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Field name={"negotiable_rate"}>
                  {({ field }) => (
                    <Form.Switch
                      label={"Negotiable"}
                      checked={field.value}
                      onChange={(e) =>
                        setFieldValue(field.name, e.target.checked)
                      }
                    />
                  )}
                </Field>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className={"mt-3"}>
            <Card.Header>
              <h5>Additional Information</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>
                  Required skills <span className={"text-danger"}>*</span>
                </Form.Label>
                <Field name={"required_skills"}>
                  {({ field }) => (
                    <Select
                      options={skills?.map((skill) => ({
                        label: skill?.name,
                        value: skill?.id,
                      }))}
                      value={selectedSkills}
                      isMulti
                      classNamePrefix={"react-select"}
                      onChange={(newValue) => {
                        setSelectedSkills(newValue);
                        setFieldValue(
                          field.name,
                          newValue.map((s) => s.value)
                        );
                      }}
                      placeholder={"Select Skills"}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Expectations<span className="text-danger">*</span>
                </Form.Label>
                <Field name={"expectations"}>
                  {({ field }) => (
                    <div className="create-course-description-textarea">
                      <TinymceEditor
                        height="13.438rem"
                        value={field.value}
                        handleChange={(newValue) => {
                          setFieldValue(field.name, newValue);
                        }}
                      />
                    </div>
                  )}
                </Field>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StudentGigForm;
