import { Button, Form } from "react-bootstrap";
import React from "react";
import paths from "../../routes/paths";
import { useNavigate } from "react-router-dom";

const StudyFieldForm = ({ hasLabel, t, as }) => {
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate(paths.cardLogin);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        <Button className="w-100" type="submit">
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};

export default StudyFieldForm;
